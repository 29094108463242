.App {
  height: calc(100vh - 56px);
}

.content {
  height: 100%;
}

.preview {
  max-width: 800px;
}

#run-button {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 2rem;
}


.social-container {
  background: #fffacd;
  padding: 50px 50px;
  position: 'absolute';
  bottom:0;
}
a.social {
  margin: 0 12rem;
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(-2px);
}
a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: #d62976;
}


.btn_name{
  margin-left:10px;
}

#gpt-button {
  position: fixed;
  bottom: 0;
  padding-top: 11px;
  padding-bottom: 10px;
  right: 5rem;
  margin: 2rem;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #e6e2e2;
  margin: 15% auto;
  padding: 25px;
  border: 0.5px solid #6c6c6c;
  width: 80%;
  max-width: 600px;
}

.close {
  color: #7e7979;
  float: right;
  font-size: 30px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: rgb(0, 0, 0);
  text-decoration: none;
  cursor: pointer;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  background-color: #1C2434;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
}

.sidebar-link {
  color: white;
  white-space: nowrap;
}

.sidebar-inventory {
  position: 'absolute';
  bottom: '0';
  left: '0';
  right: '0';
  padding: '1rem';
}

body {
  margin: 0;
  padding: 0;
}

#map {
  width: 20px;
  height: 20px;
}